<template lang="pug">
	.export.flex.items-center(
		:class="classNames"
		@click="$emit('click')"
	)
		.export__icon
			DownloadIcon(:size="18")
		.export__label Export
</template>

<script>
import DownloadIcon from '@/assets/svg/download.svg'

export default {
  name: "Export",
	components: {
		DownloadIcon,
	},
	props: {
		type: {
			default: 'gray',
			validator: value => ~[
				'white', 'gray',
			].indexOf(value),
		},
	},
	computed: {
		classNames() {
			const cn = [
				`--type-${this.type}`,
			]

			return cn
		},
	},
}
</script>

<style lang="scss" scoped>
.export {
	cursor: pointer;

	&.--type {
		&-white {
			color: color(white);
		}
		&-gray {
			color: color(gray-500);
		}
	}

	&__icon {
		margin-right: 4px;
	}
}
</style>
