import AbstractLocalStorage from './AbstractLocalStorage';

class FilterLocalStorage extends AbstractLocalStorage {
    constructor(name) {
        super();
        this.name = name;
    }

    add(name, value) {
        const currentValue = this.get();
        const data = { ...currentValue, [name]: value };
        this.set(data);
    }

    getByName(name) {
        const unParsedResult = localStorage.getItem(this.name);
        if (!unParsedResult) return

        const parsedResult = JSON.parse(unParsedResult);
        const result = Object.prototype.hasOwnProperty.call(parsedResult, name) ? parsedResult[name] : null;
        return result
    }

    get() {
        if (!this.name) this.constructorError();
        
        const unParsedResult = localStorage.getItem(this.name);
        const parsedResult = JSON.parse(unParsedResult);

        const result = {};

        if(parsedResult === null) return null;

        for (let key in parsedResult) {
            if (typeof parsedResult[key] === 'object' && Array.isArray(parsedResult[key])) {
                result[key] = parsedResult[key]
                    .map(el => el)
                    .join(',')
            }
            result[key] = parsedResult[key]
        }
        return result ?? {};
    }

}

export default new FilterLocalStorage('filter');
