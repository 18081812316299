export default ({ get, post }) => ({

  getPSOTotalData({
		stores,
		categories,
		brands,
		regions,
		dateFrom,
		dateTo,
		businessUnit,
	}) {
    return get('/dashboard/pso-total', {
		baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
		params: {
			stores,
			categories,
			brands,
			regions,
			dateFrom,
			dateTo,
			businessUnit
		}
	});
  },

  getPSODetailsData({
		stores,
		categories,
		brands,
		regions,
		dateFrom,
		dateTo,
		businessUnit,
	}) {
    return get('/dashboard/pso-details', {
			baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
			params: {
				stores,
				categories,
				brands,
				regions,
				dateFrom,
				dateTo,
				businessUnit
			}
		});
  },

	// ============Price Analysis=============
	getCompetitorsBrands() {
		return get('/price-analysis/competitor-brands');
	},

	getSellersList(params) {
		return get('/price-analysis/sellers', { params });
	},

	getPriceAnalysisCharts() {
		return get('/price-analysis/charts');
	},

	getChartData(params) {
		return post('/price-analysis/chart', params);
	},

	getPriceAnalysisProducts(params) {
		return get('/price-analysis/product-list', { params });
	}
})
