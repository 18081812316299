<template lang="pug">
	header.header.container
		.flex.justify-between.items-center
			Link(:to="{name: 'Nestle.PSOTotalDashboard'}" unstyled)
				img.header__logo(src="~@/assets/img/unilever/unilever_logo.png")
			.header__logout(@click="logout") Выйти

</template>

<script>
import { mapActions } from 'vuex'
import Link from '@/components/Link/Link.vue'

export default {
	components: {
		Link,
	},
	methods: {
		...mapActions('app', ['logout']),
	},
}
</script>

<style lang="scss" scoped>
.header {
	padding: 22px;

	&__logo {
		width: 45px;
	}

	&__logout {
		font-weight: 600;
		cursor: pointer;
	}
}
</style>
