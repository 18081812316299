import api from '@/api';
const objToUrlQuery = (params) => {
  const URLParams = new URLSearchParams();

  for (const key in params) {
    if ({}.hasOwnProperty.call(params, key)) {
      URLParams.append(key, params[key]);
    }
  }
  return URLParams;
}

export default {
  namespaced: true,
  state: {
    dataPSOTotal: {},
    dataPSODetails: {},
  },
  getters: {
    getPSOTotalData(state) {
      return state.dataPSOTotal;
    }
  },
  mutations: {
    setPSOTotalData(state, data) {
      state.dataPSOTotal = data;
    },
    setPSODetailsData(state, data) {
      state.dataPSODetails = data;
    },
  },
  actions: {
    async getPSOTotal({ commit }, params) {
      const response = await api.unilever
        .getPSOTotalData(objToUrlQuery(params))
      commit('setPSOTotalData', response.items);
    },
    async getPSODetails({ commit }, params) {
      const response = await api.unilever
        .getPSODetailsData(objToUrlQuery(params))
      commit('setPSODetailsData', response.items);
    }
  }
}
