// eslint-disable-next-line no-unused-vars
import api from '@/api'
import UserToken from '@/helpers/LocalStorage/UserToken'
import { setYMParams } from '@/helpers/YMParams';
import User from '@/helpers/LocalStorage/User';

export default {
	namespaced: true,
	state: {
		userToken: '',
		user: null,
		nestleConfig: null,
		categories: null
	},
	getters: {
		getNestleConfig(state) {
			return state.nestleConfig
		},
		getTodoById: (state) => (id) => {
			return state.categories.find(todo => todo.id === id)
		},
		getCategory: (state) => (id) =>  {
			// вызов будет выполняться так this.getCategory()(id)
			return state.categories.find(el => el.id === id)
		},
	},

	mutations: {
		setUser(state, user) {
			state.user = user
		},
		setToken(state, userToken) {
			state.userToken = userToken
		},
		setNestleConfig(state, flag) {
			state.nestleConfig = flag
		},
	},
	actions: {
		async login({ commit }, params) {
			const { token } = await api.common.login(params)
			if (token) {
				UserToken.set(token)
				commit('setToken', token)
				return { success: true }
			} else {
				return { success: false }
			}
		},
		async fetchUserID() {
			const response  = await api.common.getUserId();

			User.set(response);
			setYMParams({
				Роли: response.serviceUser ? "Admin" : "User"
			});
		},
		async logout({ commit }, params) {
			UserToken.remove();
			if (!params.silent) {
				location.href = '/'
			}
			commit('setUser', null)
		},

		async getCategories({ state }) {
			try {
				if(!state.categories){
					state.categories = await api.common.getCategories()
				}
			} catch (e) {
				console.log(e)
			}
		}
	},
}
