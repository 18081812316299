<template lang="pug">
.menu.container
	.flex.justify-between.items-center
		button.apply.align-start(
			@click="() => false"
		) PSO
		button.apply.align-start(
			:disabled="!isCheckedFull"
			@click="() => false"
		) OmniPSO

		.flex.justify-between.items-center
			filters(
				name="Store"
				:hasSelectAll="false"
				:items="filters.stores"
				:storageIsFull="storageIsFull"
				:isMainPage="isMainPage"
				:checkedStores="checkedStores.length"
				@update="getStoresFilters"
			)
			filters(
				name="Category"
				:items="filters.categories"
				@update="getCategoriesFilters"
				tree
			)
			filters(
				:class="{'disabled': !isCategoriesChecked || !filters.brands.values.length }"
				name="Brand"
				:items="filters.brands"
				@update="updateState('brands')"
			)
			filters(
				name="Location"
				:items="filters.regions"
			)

			.accuracy__date.flex.items-center.ml-20
				DatePicker(
					is-range
					v-model="rangeDates"
					:max-date="lastDayForPicker"
					:min-date="startingDate"
				)
					template(v-slot="{ inputValue, inputEvents, togglePopover }")
						.date(@click="togglePopover")
							calendar-blank.mr-4(:size="18")
							span {{ formattedDateFrom }}
							span(v-if="isRange") - {{ formattedDateTo }}

			button.apply(
				@click="clearFilters"
			) Clear

			button.apply(
				:disabled="!isCheckedFull"
				@click="updateResults"
			) Apply filter

			span.separator

			button.vocabulary(@click="showModalWithHints")
				QuestionIcon.svg-icon
				| Vocabulary

			.flex.justify-between.items-center
				Export.dashboard__export(
					type='white'
					@click="exportHandler"
					)

</template>

<script>
const MAIN_PAGE = 'Nestle.PSOTotalDashboard';
const PSO_PAGES = [MAIN_PAGE, 'Nestle.PSODetailsDashboard'];

const YESTERDAY = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000)
const STARTING_DATE = new Date(2022, 0, 1)

import { mapState, mapActions } from 'vuex'
import { DatePicker } from 'v-calendar'
import CalendarBlank from 'vue-material-design-icons/CalendarBlank.vue'
import ConfigInLocalStorage from '@/helpers/LocalStorage/Config'
import FilterInLocalStorage from '@/helpers/LocalStorage/Filter'
import Filters from './Filter.vue'
import QuestionIcon from '@/assets/svg/question.svg'
import Export from '@/components/Nestle/Export/Export.vue'
import { setYMParams } from '@/helpers/YMParams';

export default {
	components: {
		ConfigInLocalStorage,
		FilterInLocalStorage,
		CalendarBlank,
		DatePicker,
		Filters,
		QuestionIcon,
		Export
	},
    name: 'GlobalFilter',
	props: {
		title: {
			type: String,
			default: 'Filter',
		},

	},
	data() {
		return {
			checkedFilters: {
				stores: [],
				categories: [],
				brands: [],
				regions: [],
			},
			filters: {
				stores: {
					name: 'stores',
					values: [],
				},
				categories: {
					name: 'categories',
					values: [],
				},
				brands: {
					name: 'brands',
					values: [],
				},
				regions: {
					name: 'regions',
					values: [],
				},
			},
			rangeDates: {
				start: null,
				end: null,
			},
			isFetchProcessing: true,
			activeButton: false,
			isMounted: false,
			storageIsFull: false,
			lastDay: null,
		}
	},
	computed: {
		...mapState({
			stores: ({ globalFilter }) => globalFilter.stores,
			categories: ({ globalFilter }) => globalFilter.categories,
			brands: ({ globalFilter }) => globalFilter.brands,
			regions: ({ globalFilter }) => globalFilter.regions,
			isLoadingFilter: ({ globalFilter }) => globalFilter.isLoading,
		}),
		errorsAndFixesUrl() {
			return 'https://docs.google.com/spreadsheets/d/1TiA3t-cb_d0et9J6sfLje5nvcFB_w3WLMcfS7BhEfeQ/edit?usp=sharing'
		},
		additionalLink() {
			let result = null
			if (this.$route.name === 'Nestle.PSOTotalDashboard') {
				result = {
					title: 'Market categories',
					url: 'https://docs.google.com/spreadsheets/d/19H8zUA3iV529ilHaKLZcbwWjES5PSCJ5pIIWmeLPrrQ/edit#gid=0',
				}
			}
			if (this.$route.name === 'Nestle.TaxonomyPlacement') {
				result = {
					title: 'Taxonomy target',
					url: 'https://docs.google.com/spreadsheets/d/19H8zUA3iV529ilHaKLZcbwWjES5PSCJ5pIIWmeLPrrQ/edit#gid=1929339347',
				}
			}
			if (this.$route.name === 'Nestle.ContentIntegrity') {
				result = {
					title: 'Content rules',
					url: 'https://docs.google.com/spreadsheets/d/19H8zUA3iV529ilHaKLZcbwWjES5PSCJ5pIIWmeLPrrQ/edit#gid=917782129',
				}
			}
			return result
		},
		isRange() {
			return Date.parse(this.rangeDates.start) !== Date.parse(this.rangeDates.end);
		},
		formattedDateFrom() {
			return this.$utils.format(this.rangeDates.start, 'dd.MM.yyyy')
		},
		formattedDateTo() {
			return this.$utils.format(this.rangeDates.end, 'dd.MM.yyyy')
		},

		lastDayForPicker() {
			return this.lastDay
		},
		prevWeekSunday() {
			const date = new Date();
			const day = date.getDay();

			const diff = date.getDate() - day + (day === 0 ? -6 : 1);

			return new Date(date.setDate(diff - 1));
		},
		startingDate() {
			return STARTING_DATE
		},
		isCheckedFull() {
			return !!(
				this.isStoresChecked &&
				this.isCategoriesChecked &&
				this.isBrandsChecked &&
				this.isRegionsChecked
			)
		},
		checkedStores() {
			return this.filters.stores.values.filter(item => item.checked)
		},
		isStoresChecked() {
			return this.checkedStores.map(item => item.id).join(',')
		},
		isCategoriesChecked() {
			const arrayOfValues = Object.values(this.filters.categories.values)
			const result = [].concat(...arrayOfValues).filter(item => item.checked)
			return result.map(item => item.id).join(',')
		},
		isBrandsChecked() {
			const result =  this.filters.brands.values.filter(item => item.checked)
			return result.map(item => item.id).join(',')
		},
		isRegionsChecked() {
			const result =  this.filters.regions.values.filter(item => item.checked)
			return result.map(item => item.id).join(',')
		},
		isStoresLessLimits() {
			const storeLimits = ConfigInLocalStorage.getLimits('stores')
			return this.checkedStores.length <= storeLimits
		},
		isMainPage() {
			return this.$route.name === MAIN_PAGE;
		},
		isPSOPage() {
			return PSO_PAGES.includes(this.$route.name);
		},

	},
	created() {
		const config = {
			limits: {
				stores: 5,
				period: 1,
			}
		};
		ConfigInLocalStorage.set(config);
	},
	mounted() {		
		this.fetch()
	},
	methods: {
		...mapActions('globalFilter', [
			'fetchStores',
			'fetchCategories',
			'fetchBrands',
			'fetchRegions',
		]),

		exportPSOTotalDashboard() {
			console.log('exportHandler');
		},
		exportPSODetailsDashboard() {
			console.log('exportHandler');
		},

		async exportPortfolioAvailability() {
			const params = this.getParams();

			try {
				const image = await this.$api.nestle.getExportPortfolioAvailability(params)
				const fileURL = window.URL.createObjectURL(
					new Blob(
						[image], 
						{ type: "application/ vnd.openxmlformats - officedocument.spreadsheetml.sheet; charset = UTF-8" }
					)
				)
				const fileLink = document.createElement('a')

				fileLink.href = fileURL
				fileLink.setAttribute('download', 'export-portfolioAvailability.xlsx')
				document.body.appendChild(fileLink)
				fileLink.click()
			} catch (error) {
				console.log(error)
			}
		},

		async exportShareOfSearch() {
			const params = this.getParams();
			
			try {
				const image = await this.$api.nestle.getExportShareOfSearch(params)
				const fileURL = window.URL.createObjectURL(
					new Blob(
						[image], 
						{ type: "application/ vnd.openxmlformats - officedocument.spreadsheetml.sheet; charset = UTF-8" }
					)
				)
				const fileLink = document.createElement('a')

				fileLink.href = fileURL
				fileLink.setAttribute('download', 'export-shareOfSearch.xlsx')
				document.body.appendChild(fileLink)
				fileLink.click()
			} catch (error) {
				console.log(error)
			}
		},

		async exportRatingsAndReviews() {
			const params = this.getParams();

			try {
				const image = await this.$api.nestle.getExportRatingsAndReviews(params)
				const fileURL = window.URL.createObjectURL(
					new Blob(
						[image], 
						{ type: "application/ vnd.openxmlformats - officedocument.spreadsheetml.sheet; charset = UTF-8" }
					)
				)
				const fileLink = document.createElement('a')

				fileLink.href = fileURL
				fileLink.setAttribute('download', 'export-ratingsAndReviews.xlsx')
				document.body.appendChild(fileLink)
				fileLink.click()
			} catch (error) {
				console.log(error)
			}
		},

		exportTaxonomyPlacement() {
			console.log('exportHandler')
		},

		async exportImageAccuracy() {
			const params = this.getParams();

			try {
				const image = await this.$api.nestle.getExportImageAccuracy(params)
				const fileURL = window.URL.createObjectURL(
					new Blob(
						[image], 
						{ type: "application/ vnd.openxmlformats - officedocument.spreadsheetml.sheet; charset = UTF-8" }
					)
				)
				const fileLink = document.createElement('a')

				fileLink.href = fileURL
				fileLink.setAttribute('download', 'export-imageAccuracy.xlsx')
				document.body.appendChild(fileLink)
				fileLink.click()
			} catch (error) {
				console.log(error)
			}
		},

		async exportHandler() {
			if (this.$route.name === 'Nestle.PSOTotalDashboard') {
				await this.exportPSOTotalDashboard();
			} else if (this.$route.name === 'Nestle.PSODetailsDashboard') {
				await this.exportPSODetailsDashboard();
			} else if (this.$route.name === 'Nestle.PortfolioAvailability') {
				await this.exportPortfolioAvailability()
			} else if (this.$route.name === 'Nestle.ShareOfSearch') {
				await this.exportShareOfSearch()
			} else if (this.$route.name === 'Nestle.RatingsAndReviews') {
				await this.exportRatingsAndReviews()
			} else if (this.$route.name === 'Nestle.TaxonomyPlacement') {
				await this.exportTaxonomyPlacement();
			} else if (this.$route.name === 'Nestle.ImageAccuracy') {
				await this.exportImageAccuracy();
			}
		},

		// TODO вынести в модель
		getStorage() {
			const defaultStorage = {
				stores: '',
				categories: '',
				brands: '',
				regions: '',
				dateFrom: '',
				dateTo: '',
			}
			return FilterInLocalStorage.get() || defaultStorage;
		},
		normalizeStorage({
			stores,
			categories,
			brands,
			regions,
		}) {
			return {
				stores: stores.split(',').map(Number) || [],
				categories: categories.split(',').map(Number) || [],
				brands: brands.split(',').map(Number) || [],
				regions: regions.split(',').map(Number) || [],
			}
		},
		// TODO check it
		updateState() {
			const brands = this.filters.brands.values
			this.checkedFilters.brands.splice(0)
			brands.forEach(brand => {
				if(brand.checked) {
					this.checkedFilters.brands.push(Number(brand.id))
				}
			})
		},
		async clearStoresFilter() {
			await this.fetchStores({ categories: this.isCategoriesChecked })
			this.filters.stores.values = this.stores
		},
		async getCategoriesFilters() {
			if (this.isCategoriesChecked) {
				if (this.isStoresChecked) {
					this.getBrands()
				} else {
					this.clearStoresFilter()
					
					const categories =  this.filters.categories.values
					this.checkedFilters.categories = []

					for (let key in categories) {
						categories[key].forEach(category => {
							if(category.checked) {
								this.checkedFilters.categories.push(Number(category.id))
							}
						})
					}
				}
			} else {
				this.clearStoresFilter()
				this.filters.brands.values = []
			}
		},
		getStoresFilters() {
			if (this.isCategoriesChecked) {
				if (this.isStoresChecked) {
					this.getBrands()
				} else {
					this.fetchStores()
					this.getCategories()
					this.filters.brands.values = []
				}
			} else {
				this.getCategories()
			}
		},
		async getBrands() {
			if(this.isStoresChecked && this.isCategoriesChecked) {
				const params = {
					stores: this.isStoresChecked,
					categories: this.isCategoriesChecked,
				}
					
				await this.fetchBrands(params)
				this.filters.brands.values = this.brands
				this.checkAllItems(this.filters.brands.values)
			}
		},
		getLastDayValue() {
			return this.isPSOPage ? this.prevWeekSunday : YESTERDAY;
		},
		initDates() {
			const lastDay = this.getLastDayValue();
			const initialData = {
				dateFrom: lastDay,
				dateTo: lastDay,
			}

			const LSDateFrom = FilterInLocalStorage.getByName('dateFrom')
			const LSDateTo = FilterInLocalStorage.getByName('dateTo')

			// TODO Оптимизировать
			if (!LSDateFrom) {
				FilterInLocalStorage.add('dateFrom', initialData.dateFrom)
				this.rangeDates.start = lastDay
			} else {
				this.rangeDates.start = this.$utils.parseISO(LSDateFrom)
			}

			if (!LSDateTo) {
				FilterInLocalStorage.add('dateTo', initialData.dateTo)
				this.rangeDates.end = lastDay
			} else {
				this.rangeDates.end = this.$utils.parseISO(LSDateTo)
			}
		},

		prepareArray(checkedArray, arrayOfData) {
			checkedArray.forEach((checkedItem) => {
				arrayOfData.forEach((item) => {
					if (Number(item.id) === Number(checkedItem)) {
						this.$set(item, 'checked', true )
					}
				})
			})

			return arrayOfData
		},

		prepareCategories(checkedArray, arrayOfData) {
			checkedArray.forEach((checkedItem) => {
				Object.values(arrayOfData).forEach((item) => {
					item.forEach(el => {
						if (Number(el.id) === Number(checkedItem)) {
							this.$set(el, 'checked', true)
						}
					})
				})
			})
			return arrayOfData
		},

		checkFulledStorage(storage) {
			return Object.prototype.hasOwnProperty.call(storage, 'stores') &&
				Object.prototype.hasOwnProperty.call(storage, 'categories') &&
				Object.prototype.hasOwnProperty.call(storage, 'brands') &&
				Object.prototype.hasOwnProperty.call(storage, 'regions') &&
				!!storage?.stores.length &&
				!!storage?.categories.length &&
				!!storage?.brands.length &&
				!!storage?.regions.length
		},

		checkAllItems(arr) {
			Object.values(arr).forEach(item => this.$set(item, 'checked', true))
		},
		checkItemsByName(arr, arrayOfNames) {
			arrayOfNames.forEach((name) => {
				Object.values(arr).forEach((item) => {
					if (name === item.name) {
						this.$set(item, 'checked', true)
					}
				})
			})
		},
		async fetch() {
			const rawStorage = this.getStorage()

			this.storageIsFull = this.checkFulledStorage(rawStorage)

			await this.fetchStores({ categories: this.isCategoriesChecked })
			await this.getCategories()
			
			await this.fetchRegions()

			// При 1й загрузке проверяем, есть ли сохраненные значения в LocalStorage
			// Если есть, подгружаем фильтры с учетом LocalStorage
			if (this.storageIsFull) {
				const storage = this.normalizeStorage(rawStorage)
				this.filters.stores.values = this.prepareArray(storage.stores, this.stores)
				this.filters.regions.values = this.prepareArray(storage.regions, this.regions)

				const temporaryCategories = this.filters.categories.values
				this.filters.categories.values = this.prepareCategories(storage.categories, temporaryCategories)

				await this.getBrands()
				const temporaryBrands = this.filters.brands.values
				this.filters.brands.values = this.prepareArray(storage.brands, temporaryBrands)
			} else {
				this.filters.stores.values = this.stores
				this.filters.regions.values = this.regions

				const defaultCheckedRegions = ['Москва']
				this.checkItemsByName(this.filters.regions.values, defaultCheckedRegions)

				if (!this.storageIsFull && this.isMainPage) {
					this.checkAllItems(this.filters.stores.values)
				}
			}

			this.$emit('filter-status', this.isCheckedFull)

			this.isMounted = true
			this.$emit('check-fulled-storage', this.storageIsFull)
		},
		async getCategories() {
			const newOnj = {}

			try {
				this.isFetchProcessing = true
				const filters = await this.$api.nestle.getParentCategories()
				await this.fetchCategories({ stores: this.isStoresChecked })

				filters.forEach(parentCategory => {
					this.categories.forEach(category => {
						category.filterId.forEach((item) => {
							if(item === +parentCategory.id) {
								if (!Object.prototype.hasOwnProperty.call(newOnj, parentCategory.name)) {
									newOnj[parentCategory.name] = []
								}
								newOnj[parentCategory.name].push({
									id: category.id,
									parentId: parentCategory.id,
									name: category.name,
									checked: category.checked || null,
									filterId: category.filterId,
								})
							}
						})
					})
				})

				const sortedParentCategories = Object.keys(newOnj)
					.sort()
					.reduce((obj, key) => { 
						obj[key] = newOnj[key]; 
						return obj;
					}, {})

				this.filters.categories.values = sortedParentCategories
			} catch(error) {
				console.error(error)
			} finally {
				this.isFetchProcessing = false
			}
		},
		// TODO проверить и оптимизировать
		clearFilters() {
			this.checkedFilters = {
				stores: [],
				categories: [],
				brands: [],
				regions: [],
			}

			this.filters = {
				stores: {
					name: 'stores',
					values: [],
				},
				categories: {
					name: 'categories',
					values: [],
				},
				brands: {
					name: 'brands',
					values: [],
				},
				regions: {
					name: 'regions',
					values: this.filters.regions.values,
				},
			}

			FilterInLocalStorage.remove()

			const lastDay = this.getLastDayValue();

			FilterInLocalStorage.add('dateFrom', lastDay)
			FilterInLocalStorage.add('dateTo', lastDay)
			this.rangeDates = {
				start: lastDay,
				end: lastDay
			}

			this.$emit('filter-status', this.isCheckedFull)

			this.fetch();

			this.activeButton = true
		},

		getParams() {
			const rawStorage = this.getStorage()

			const result = {
				stores: rawStorage.stores,
				categories: rawStorage.categories,
				brands: rawStorage.brands,
				regions: rawStorage.regions,
				dateFrom: this.$utils.format(this.$utils.parseISO(rawStorage.dateFrom)) || null,
				dateTo: this.$utils.format(this.$utils.parseISO(rawStorage.dateTo)) || null,
			}
			return result
		},

		updateResults() {
			this.activeButton = false

			if (this.isMainPage && !this.isStoresLessLimits) {
				const params = {
					stores: this.isStoresChecked,
					categories: this.isCategoriesChecked,
					brands: this.isBrandsChecked,
					regions: this.isRegionsChecked,
					dateFrom: this.$utils.format(this.rangeDates.start),
					dateTo: this.$utils.format(this.rangeDates.end),
				}
				
				this.$emit('update-filters', params)
				this.$emit('filter-status', this.isCheckedFull)
			} else {
				FilterInLocalStorage.add('stores', this.isStoresChecked)
				FilterInLocalStorage.add('categories', this.isCategoriesChecked)
				FilterInLocalStorage.add('brands', this.isBrandsChecked)
				FilterInLocalStorage.add('regions', this.isRegionsChecked)

				FilterInLocalStorage.add('dateFrom', this.rangeDates.start)
				FilterInLocalStorage.add('dateTo', this.rangeDates.end)
				
				this.$emit('update-filters', this.getParams())
				this.$emit('filter-status', this.isCheckedFull)
			}

			setYMParams({
					Ритейлы:  this.checkedStores.map(store => store.name),
			});
		},

		showModalWithHints() {
			this.$modal({
				component: 'Hints',
				props: {

				},
				on: {
					cancel: () => {
						console.log('cancel')
					},
					save: () => {
						console.log('save')
					}
				},
			})
		},

	},

	watch: {
		rangeDates: {
			immediate: true,
			handler(value) {
				if (!value.end && !value.start) {
					this.initDates()
				} else {
					FilterInLocalStorage.add('dateFrom', value.start)
					FilterInLocalStorage.add('dateTo', value.end)
				}
				this.activeButton = true
			}
		},
		'$route.name': {
			deep: true,
			immediate: true,
			handler(currentValue, prevValue) {
				const rawStorage = this.getStorage()
				const isStorageFull = this.checkFulledStorage(rawStorage)

// BUSINESS: The functional of limit lastDate for datepicker

//  If: PSO pages, last day for request is a previous Sunday
//  Else: 2 days before today

				this.lastDay = this.getLastDayValue();

				if (!isStorageFull) {
					this.rangeDates = {
						start: this.lastDay,
						end: this.lastDay
					};
				}

				if (prevValue === MAIN_PAGE) {
					if (this.isStoresLessLimits) {
						if (isStorageFull) {
							this.$emit('filter-status', true)
							this.$emit('update-filters', this.getParams())
						}
					} else {
						this.clearFilters();
					}
				} else if (currentValue === MAIN_PAGE) {
					if (isStorageFull) {
						this.$emit('filter-status', true)
						this.$emit('update-filters', this.getParams())
					}

				} else {
					if (isStorageFull) {
						this.$emit('filter-status', true)
						this.$emit('update-filters', this.getParams())
					}
				}
			},
		},
	},

}
</script>

<style lang="scss" scoped>

.date {
	display: flex;
}

.menu {
	width: 100%;
	padding: 12px 24px;
	background-color: color(gray-800);

	&__title {
		min-height: 32px;
		color: color(white);
		font-size: 24px;
		line-height: 1.33;

		&.--nestle {
			width: 280px; // todo for demo only
		}
	}
	&__nav {
		width: 640px; // todo for demo only

		&-wrap {
			max-width: 450px;
		}
	}
	&__item {
		font-size: 12px;
		color: color(white);
	}
}


.accuracy {
	&__nav {
		width: 190px;
	}
	&__date {
		// width: 100px;
		color: color(white);
		cursor: pointer;
	}
	&__section {
		padding: 20px;
		background-color: color(white);
		border-radius: 4px;
	}
	&__chart {
		::v-deep .chart {
			height: 340px;
		}
	}
	&__product {
		width: 32%;
	}
}
.header {
	margin-bottom: 32px;

	&-right {
		margin-left: auto;
	}
}
.filter {
	&__list {
		max-height: 200px;
		overflow: auto;
	}

	&__label {
		margin-left: 10px;
		padding: 0 4px 0 10px;
		outline: 1px color(gray-400) solid;
		border-radius: 3px;
		color: color(gray-700);
		background-color: color(white);
		cursor: pointer;

		&-icon {
			margin-left: 4px;
		}
	}

	&__bottom {
		margin-top: 15px;
		padding-top: 5px;
		border-top: 1px solid color(gray-300);
	}
	&__clear {
		color: color(blue-bright);
		cursor: pointer;
	}
	&__apply {
		padding: 4px 10px 5px 10px;
		background-color: color(blue-bright);
		color: color(white);
		cursor: pointer;
		border-radius: 4px;
	}
}

select {
	padding: 0 5px;
	outline: 1px color(gray-400) solid;
	border-radius: 3px;
	color: color(gray-700);

	&:focus {
		outline: none;
	}
}

.apply {
	margin-left: 20px;
	border: 1px solid #ffffff;
	padding: 2px 5px;
	cursor: pointer;
	color: #fff;
	border-radius: 3px;
  &.align-start:not(:first-child) {
    margin: 0 auto 0 0;
  }
  &.align-start:first-child {
    margin: 0 20px 0 0;
  }
	&:disabled {
		cursor: not-allowed;
		color: gray;
		background-color: lightgray;
		border-color: lightgray;
	}
	&:focus {
		border: 1px solid black;
		outline: inherit;
	}
}
.disabled {
	pointer-events: none;

	::v-deep .filter__label {
		color: gray;
	}
}
.additional-link {
	color: #ffffff;
	margin-right: 20px;
}
.separator {
    display: block;
    width: 1px;
    height: 27px;
    background-color: rgba(255, 255, 255, 0.4);
    margin-left: 24px;
    margin-right: 24px;
}
.vocabulary {
	display: flex;
    align-items: center;
    color: #ffffff;
	margin-right: 24px;
	&:focus {
		outline: none;
	}
}
.svg-icon {
	margin-right: 4px;
}
</style>
