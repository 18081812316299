<template lang="pug">
main.layout
	template(v-if="isResetCache")
		RouterView
	template(v-else)
		Header
		global-filter(
			@update-filters="updateFilters"
			@filter-status="filterStatus"
		)
		.layout__content
			RouterView(
				v-bind="params"
				:need-update-result="isNeedUpdate"
				:date="date"
				:isFilterFull="isFilterFull"
			)
</template>

<script>
import Header from '@/components/Nestle/Layout/Header.vue'
import GlobalFilter from '@/components/Nestle/GlobalFilter/GlobalFilter.vue'

export default {
	components: {
		Header,
		GlobalFilter,
	},

	data() {
		return {
			isNeedUpdate: false,
			date: {
				dateFrom: new Date(new Date().getFullYear(), new Date().getMonth()-1, 0),
				dateTo: new Date(),
			},
			isFilterFull: false,
			params: null,
		}
	},

	computed: {
		// TODO после сброса кэша не появляется дата в LS и сама дата
		isResetCache() {
			return this.$route.name === 'Unilever.ResetCache';
		},

	},

	methods: {
		updateFilters(value) {
			this.params = value
			this.isNeedUpdate = !this.isNeedUpdate;
		},

		filterStatus(value) {
			this.isFilterFull = value;
		},

	},

}
</script>

<style lang="scss">
.layout {
	min-height: 100vh;
	background-color: color(gray-200);
	min-width: 1280px;
}
</style>
